@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'














































































































































































































































































































































































































































.v-text-field--text-right::v-deep
  input
    text-align: right

.v-data-table::v-deep
    tbody > tr > td, thead > tr > th
      padding-right: 0 !important
      padding-left: 10px !important

      &:last-child
        padding-right: 10px !important

    tbody >
      tr > td
        .v-btn.v-btn--icon, .v-text-field.v-text-field--solo
          margin-left: -6px !important

      tr:hover
        &:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
          td:first-child
            .v-btn.v-btn--icon
              color: var(--v-error-base) !important
              caret-color: var(--v-error-base) !important

        &:last-child
          background-color: #FFFFFF !important
